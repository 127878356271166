import React from "react"

import SyntaxHighlighter from 'react-syntax-highlighter'

/**
 * Minimum SyntaxHighlighterWrapper properties. 
 * The actual SyntaxHighlighter inherits all properties.
 */
interface SyntaxHighlighterWrapperProps {
  language: string
  style: any
  filename: string
  content: string
}

/**
 * Wrapper component for a syntax highlighter with an extra header.
 */
export default class SyntaxHighlighterWrapper
  extends React.Component<SyntaxHighlighterWrapperProps> {

  /**
   * Renders the component.
   */
  render() {

    if (this.props.content !== "") {
      return (
        <div>
          <h1>Complexicon Results for {this.props.filename}</h1>
          <SyntaxHighlighter {...this.props}>
            {this.props.content}
          </SyntaxHighlighter>
        </div>
      )
    } else {
      // Display nothing
      return (<div></div>)
    }
  }
}
