import React from "react"
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import Layout from "../components/center-layout"
import SEO from "../components/seo"

import SyntaxHighlighterWrapper 
	from "../components/complexicon/syntax-highlighter-wrapper"


/**
 * Page component.
 */
export default class ComplexiconPage extends React.Component {

  fileReader = undefined

  STATE_INPUT_FILE = "inputFile"
  STATE_COMPLEXICON_SOURCE = "complexiconLog"

	/**
	 * Page state.
	 */
	state: any = {
    [this.STATE_INPUT_FILE]: "",
    [this.STATE_COMPLEXICON_SOURCE]: ""
  }

  /**
   *  Handles file input & state management.
   */
	handleInput = (event: any) => {

    const target = event.target
    const value = target.value
    const name = target.name

    // For file input events, save the file object instead of just the target value
    const stateValue = (target.files !== undefined) ? target.files[0] : value

    this.setState({
      [name]: stateValue
    })
  }

  /**
   * Handles reading an input file once it is selected.
   */
	handleFileRead = (event: any) => {
    console.log("Handling...");

    const file = event.target.result
    const lines = file.split(/\r\n|\n/)

    if (lines !== undefined) {
      this.clearSource()
      this.logSource(lines)
      this.parseLines(lines)
    } else {
      console.error("Reading file failed");
      
    }
  }

  /**
   * Parses an array of lines to determine cyclomatic complexity.
   */
	parseLines = (lines: string[]) => {
    lines.forEach(line => {
      console.log(line)
    });
  }

  /**
   * Prepares a file input selection to be read as text for parsing.
   */
	parseInput = () => {
    let infile = this.state[this.STATE_INPUT_FILE]
    console.log(`Parsing input ${infile.name}...`);
    
    this.fileReader = new FileReader()
    this.fileReader.onload = this.handleFileRead
    this.fileReader.readAsText(infile)
  }

  /**
   * Updates the Complexicon Source state to reflect lines to be parsed.
   */
	logSource = (arr: string[]) => {

    arr.forEach(str => {
      const origState = this.state[this.STATE_COMPLEXICON_SOURCE] + '\n'
      
      this.setState({
        [this.STATE_COMPLEXICON_SOURCE]: origState + str
      })
    });

  }

  /** 
	* Clears the Complexicon Source state. 
	*/
  clearSource = () => {
    this.setState({[this.STATE_COMPLEXICON_SOURCE]: ""})
  }

  /**
   * Renders the component.
   */
	render(): JSX.Element {

    const infilename = this.state[this.STATE_INPUT_FILE].name

    return (
      <Layout>
        <SEO title="Complexicon" />
        <h1>Complexicon: A program that measures cyclomatic complexity of (Javascript) code.</h1>

        <input type="file" name={this.STATE_INPUT_FILE} onChange={this.handleInput}/>
        <button onClick={this.parseInput}>Parse file</button>

        <SyntaxHighlighterWrapper language="javascript" style={docco} content={this.state[this.STATE_COMPLEXICON_SOURCE]} filename={infilename}/>
        
      </Layout>
    )
  }
}
